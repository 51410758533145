import get from 'lodash/get';
import { FALLBACK_MESSAGE, isFalsyString, sentenceCase } from '@emobg/web-utils';
import { RECORD_TYPES } from '@emobg/motion-ui';
import { COLORS, GRAYSCALE } from '@emobg/vue-base';
import { CRM_PERMISSIONS } from '@domains/CRM/const/permissions';
import CRM_ROUTES from '@domains/CRM/router/CRMRouterMap';
import { PermissionLink } from '@/components';
import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';

const badgeColor = {
  active: COLORS.success,
  inactive: GRAYSCALE.ground,
};

export function contentCells() {
  return [
    {
      title: 'Number',
      attributeName: 'number',
      displayPriority: 1,
    },
    {
      title: 'Provider',
      attributeName: 'provider.name',
      transformValue: sentenceCase,
      displayPriority: 1,
    },
    {
      title: 'Serial number',
      attributeName: 'serial_number',
      displayPriority: 1,
    },
    {
      attributeName: 'status',
      title: 'Status',
      displayPriority: 1,
      type: RECORD_TYPES.component,
      component: BadgeComponent,
      props: ({ status }) => ({
        text: sentenceCase(status),
        color: badgeColor[status],
      }),
    },
    {
      title: 'Badge spot',
      attributeName: 'badge_spot',
      transformValue: sentenceCase,
      displayPriority: 0,
    },
    {
      attributeName: 'user_name',
      title: 'User',
      displayPriority: 0,
      minWidth: 180,
      type: RECORD_TYPES.component,
      component: PermissionLink,
      props: result => {
        const userUuid = get(result, 'user_uuid', 'null');
        return {
          classLink: 'emobg-link-primary emobg-body-2',
          to: !isFalsyString(userUuid) ? {
            name: CRM_ROUTES.users.detail.index,
            params: { userUuid },
          } : null,
          linkPermissions: [CRM_PERMISSIONS.viewCrmUsers],
          text: !isFalsyString(get(result, 'user_name', 'null')) ? `${get(result, 'user_name')}` : FALLBACK_MESSAGE.dash,
        };
      },
    },
    {
      title: 'Delivery address',
      attributeName: 'delivery_address',
      transformValue: sentenceCase,
      displayPriority: 0,
    },
    {
      title: 'Delivery method',
      attributeName: 'delivery_method',
      transformValue: sentenceCase,
      displayPriority: 0,
    },
    {
      title: 'Delivery status',
      attributeName: 'delivery_status',
      transformValue: sentenceCase,
      displayPriority: 0,
    },
    {
      title: 'Delivery cost',
      attributeName: 'delivery_cost',
      transformValue: sentenceCase,
      displayPriority: 0,
    },
  ];
}

export const facets = [
  {
    type: 'refinementList',
    props: {
      title: 'Status',
      attributeName: 'status',
      transformValue: sentenceCase,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Delivery status',
      attributeName: 'delivery_status',
      transformValue: sentenceCase,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Provider',
      attributeName: 'provider.name',
    },
  },
  {
    type: 'refinementList',
    props: {
      toggled: true,
      title: 'Delivery method',
      attributeName: 'delivery_method',
      transformValue: sentenceCase,
    },
  },
];
