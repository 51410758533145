<script>
import { MuiAlgoliaList } from '@emobg/motion-ui';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import {
  contentCells,
  facets,
} from './config';

export default {
  name: 'BadgesListView',
  components: { MuiAlgoliaList },
  created() {
    this.BADGES_INDEX = ALGOLIA_INDEXES.badges;
    this.facets = facets;
    this.mainConfig = contentCells();
  },
};
</script>

<template>
  <div
    class="BadgesListView"
    data-test-id="badges_list-view"
  >
    <h1
      class="mb-2"
      data-test-id="title"
    >
      Badges
    </h1>
    <MuiAlgoliaList
      :facets="facets"
      :index="BADGES_INDEX"
      :table-config="mainConfig"
      data-test-id="list"
    />
  </div>
</template>
