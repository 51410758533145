var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "BadgesListView",
      attrs: { "data-test-id": "badges_list-view" },
    },
    [
      _c("h1", { staticClass: "mb-2", attrs: { "data-test-id": "title" } }, [
        _vm._v(" Badges "),
      ]),
      _c("MuiAlgoliaList", {
        attrs: {
          facets: _vm.facets,
          index: _vm.BADGES_INDEX,
          "table-config": _vm.mainConfig,
          "data-test-id": "list",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }