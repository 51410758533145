import get from 'lodash/get';
import { FALLBACK_MESSAGE, isFalsyString, sentenceCase } from '@emobg/web-utils';
import { RECORD_TYPES } from '@emobg/motion-ui';
import { COLORS, GRAYSCALE } from '@emobg/vue-base';
import { CRM_PERMISSIONS } from '@domains/CRM/const/permissions';
import CRM_ROUTES from '@domains/CRM/router/CRMRouterMap';
import { PermissionLink } from '@/components';
import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';

const badgeColor = {
  delivered: COLORS.success,
  inactive: COLORS.danger,
  pending: COLORS.warning,
  unassigned: GRAYSCALE.ground,
};

export function contentCells() {
  return [
    {
      attributeName: 'user_name',
      title: 'User',
      displayPriority: 1,
      minWidth: 180,
      type: RECORD_TYPES.component,
      component: PermissionLink,
      props: result => {
        const userUuid = result.user_uuid || get(result, 'user.uuid', 'null');
        return {
          classLink: 'emobg-link-primary emobg-body-2',
          to: !isFalsyString(userUuid) ? {
            name: CRM_ROUTES.users.detail.index,
            params: { userUuid },
          } : null,
          linkPermissions: [CRM_PERMISSIONS.viewCrmUsers],
          text: !isFalsyString(get(result, 'user_name', 'null')) ? result.user_name : FALLBACK_MESSAGE.dash,
        };
      },
    },
    {
      title: 'Badge number',
      attributeName: 'badge_number',
      displayPriority: 1,
    },
    {
      title: 'Serial number',
      attributeName: 'badge_serial',
      displayPriority: 1,
    },
    {
      title: 'Delivery address',
      attributeName: 'address',
    },
    {
      title: 'Delivery method',
      attributeName: 'delivery_method',
      transformValue: sentenceCase,
    },
    {
      attributeName: 'status',
      title: 'Delivery status',
      displayPriority: 1,
      type: RECORD_TYPES.component,
      component: BadgeComponent,
      props: ({ status }) => ({
        text: sentenceCase(status),
        color: badgeColor[status],
      }),
    },
    {
      title: 'Delivery cost',
      attributeName: 'cost',
    },
  ];
}

export const facets = [
  {
    type: 'refinementList',
    props: {
      title: 'Status',
      attributeName: 'status',
      transformValue: sentenceCase,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Delivery method',
      attributeName: 'delivery_method',
      transformValue: sentenceCase,
    },
  },
];
