<script>
import { PageTabs, PageView } from '@/components';
import badges from './router/BadgesRouterMap';

export default {
  name: 'BadgesView',
  components: {
    PageTabs,
    PageView,
  },
  created() {
    this.tabs = [
      {
        label: {
          title: 'Badges',
        },
        url: badges.list,
      },
      {
        label: {
          title: 'Badge deliveries',
        },
        url: badges.deliveries,
      },
    ];
  },
};
</script>

<template>
  <div class="BadgesView d-flex flex-column flex-fill">
    <PageView class="emobg-background-color-white py-4">
      <h2>
        Badges
      </h2>
    </PageView>
    <PageTabs
      :tabs="tabs"
      has-overview
    />
    <PageView is-tab-content>
      <Transition name="page">
        <RouterView />
      </Transition>
    </PageView>
  </div>
</template>
