<script>
import { mapGetters } from 'vuex';
import { MuiAlgoliaList } from '@emobg/motion-ui';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import {
  contentCells,
  facets,
} from './config';

export default {
  name: 'DeliveriesListView',
  components: { MuiAlgoliaList },
  computed: {
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'getOperatorFilter',
    ]),
  },
  created() {
    this.DELIVERIES_INDEX = ALGOLIA_INDEXES.badgesDeliveries;
    this.facets = facets;
    this.mainConfig = contentCells();
  },
};
</script>

<template>
  <div
    class="DeliveriesListView"
    data-test-id="badge_deliveries_list-view"
  >
    <h1
      class="mb-2"
      data-test-id="title"
    >
      Badge deliveries
    </h1>

    <MuiAlgoliaList
      :facets="facets"
      :filters="getOperatorFilter({ attribute: 'cs_operator_fk' })"
      :index="DELIVERIES_INDEX"
      :table-config="mainConfig"
      data-test-id="list"
    />
  </div>
</template>

